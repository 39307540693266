import React, { Component } from 'react'
import { captureException, reportErrorToSentry } from 'utils/sentry';
import { Typography, Button } from '@partner-tech/pax-ui-kit';

export default class ErrorBoundary extends Component {

    constructor(props) {
      super(props)
    
      this.state = {
         hasError: false
      }
    }

    componentDidCatch(error, info){
        this.setState({hasError:true, error});
        reportErrorToSentry(error, info);
        captureException(error, (scope) => {
            scope.setExtra('componentStack', info.componentStack);
            return scope;
          });
    }

  render() {
    if (this.state.hasError) {
       return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography variant="body1">
            An error occurred. Sit tight, our engineers are currently on it! 🚒👷
          </Typography>
          <Button variant="outlined" style={{ marginTop: 15 }} onClick={() => window.location.reload()}>
            Reload
          </Button>
        </div>
       ) 
    }
    return this.props.children;
  }
}