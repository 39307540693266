import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import 'core-js/modules/es.set';
import 'core-js/modules/es.array.find-index';
import App from './components/app';
import { initSentry } from 'utils/sentry';
import { onDOMContentLoaded, onLoad } from 'utils/performance-metrics';
import {PaxThemeProvider} from "@partner-tech/pax-ui-kit/styles";
import './index.scss';
import {theme} from "@partner-tech/pax-ui-kit";

window.addEventListener('load', onLoad);

document.documentElement.style.setProperty('--page-backgroundColor', theme.palette.semantic.background.default);
document.documentElement.style.setProperty('--body-textColor', theme.palette.semantic.text.body);
document.documentElement.style.setProperty('--heading-textColor', theme.palette.semantic.text.primary);

document.addEventListener('DOMContentLoaded', (event) => {
  onDOMContentLoaded(event)
  window.RetailPortal.eventEmitter.on('application-ready', () => {
    initSentry();
    ReactDOM.render(
      <React.StrictMode>
        <Router>
          <PaxThemeProvider>
            <App />
          </PaxThemeProvider>
        </Router>
      </React.StrictMode>,
      document.getElementById('app-container')
    );
  });
});
