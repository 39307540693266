export function withScope(callback) {
    if (window.RetailPortal?.Sentry) {
      window.RetailPortal.Sentry.withScope(callback);
    }
  }
  
  export function captureException(error, scope) {
    if (window.RetailPortal?.Sentry) {
      window.RetailPortal.Sentry.captureException(error, scope);
    }
  }
  
  export function captureEvent(event) {
    if (window.RetailPortal?.Sentry) {
      window.RetailPortal.Sentry.captureEvent(event);
    }
  }
  
  export function reportErrorToSentry(error, errorinfo) {
    captureException(error, scope => {
      if (errorinfo) {
        scope.setExtra('info', errorinfo);
      }
      if (error.stack) {
        scope.setExtra('stackTrace', error.stack);
      }
      return scope;
    });
  }
  export function initSentry() {
    if (window.RetailPortal?.Sentry) {
      window.RetailPortal.Sentry.configureScope(scope => {
        scope.addEventProcessor(event => {
          event.environment = process.env.NODE_ENV ? 'production' : 'staging';
          event.tags = { ...event.tags };
          return event;
        });
      });
    }
  }