import { onFCP, onTTFB, onLCP } from "web-vitals";

import { withScope, captureEvent } from './sentry';

function notifySentry(metricsName, metricsValue) {
  const performanceTracking = 'performance-tracking';
  const performanceMetric = 'performance-metric';

  withScope(scope => {
    scope.setLevel('info');
    scope.setTransactionName(performanceTracking);
    scope.setTag(performanceMetric, metricsName);

    captureEvent({ message: performanceTracking, extra: { metricsValue } });
  });
}

function throttleNotifySentry(metricsName, metricsValue) {
  const PERFORMANCE_METRICS_THRESHOLD = 604800000 // one week
  const currentTime = Date.now();
  const storedTimestamp = localStorage.getItem(metricsName);

  // Check if it's been more than one week since the last metrics was sent
  if (!storedTimestamp || currentTime - Number(storedTimestamp) > PERFORMANCE_METRICS_THRESHOLD) {
    localStorage.setItem(metricsName, JSON.stringify(currentTime));
    notifySentry(metricsName, metricsValue);
  }
}

export function onDOMContentLoaded(event) {
  throttleNotifySentry('dom-loaded', event.timeStamp / 1000);
}

export function onLoad(event) {
  throttleNotifySentry('all-loaded', event.timeStamp / 1000);
}

onTTFB(response => {
  throttleNotifySentry('ttfb', response.value / 1000);
});

onFCP(response => {
  throttleNotifySentry('fcp', response.value / 1000);
});

onLCP(response => {
  throttleNotifySentry('lcp', response.value / 1000);
});